<template>
  <div class="col-md-8 col-lg-8 mx-auto">
    <form @submit.prevent>
      <vnud-card class="card-login card-plain">
        <template v-slot:header>
          <div>
            <div class="logo-container mb-4 text-center">
              <img src="https://instaevent.imgix.net/files/instaevent-logo-large.png?w=125" alt="InstaEvent">
            </div>
            <h1 class="home-box mb-0">InstaEvent</h1>
            <p class="home-box-text">Instantly create private events</p>
          </div>
        </template>

        <div class="getStarted mx-auto">
          <n-button class="w-100 text-white" type="primary" round block @click="$router.push('/auth/login')">
            Get Started
          </n-button>
          <div class="d-flex justify-content-between align-items-center gap-2">
            <h6>
              <router-link class="link footer-link text-white" to="/auth/register">
                Create Account
              </router-link>
            </h6>
            <h6><a href="" class="link footer-link text-white">Need Help?</a></h6>
          </div>
        </div>
      </vnud-card>
    </form>
  </div>
</template>
<script>
import { userHasValidLogin } from '@/utils/auth.js'
export default {
  name:'home-page',
  mounted() {
    if (userHasValidLogin()) {
      this.$router.push('/dashboard')
    }
  }
}
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.home-box {
  color: white;
  font-size: 3.0em;
  text-align: center;
}

.home-box-text {
  color: white;
  font-size: 1.1em;
  text-align: center;
}

.getStarted {
  max-width: 290px;
  width: 100%;
}
</style>

<template>
  <div class="table-responsive-sm">
    <table class="table inviteGuest mb-0">
      <thead>
        <tr>
          <th>
          </th>
          <th>Guest Name</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(record, index) in data" :key="index">
          <td style="min-width: 40px">
            <img v-if="record.user && record.user.picture" :src="record.user.picture" class="mb-0"
              style="min-width: 40px; width:40px;height:40px;" />
            <vue-gravatar class="mb-0" v-else
              :email="(record.user && record.user.email) ? record.user.email : record.contact.email"
              style="width:40px;height:40px;" default="retro" />
          </td>
          <td>
            <div class="d-flex flex-column">
              <a :href="record.contact ? (record.contact.id ? `/contacts/${record.contact.id}` : '') : '#'"
                class="text-body text-decoration-none mb-0">
                {{ record.user ? (record.user.fullname ? record.user.fullname : record.user.name) :
                  record.contact.name }} </a>
              <span class="text-muted text-small">
                {{ record.user ? (record.user.email ? record.user.email : '') : record.contact.email }}
              </span>
            </div>

          </td>
          <td>{{ record.attendee.status }}</td>
          <td class="text-right">
            <button class="btn btn-sm" @click="removeGuest(record.attendee.id)"
              v-if="isHost && eventInFuture && (((record.user && record.user.email) ? record.user.email : record.contact.email) !== user.email)">
              <i class="now-ui-icons ui-1_simple-remove"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <n-button v-if="isHost && eventInFuture" class="floatright" type="primary" round="" icon="" size="lg"
    @click="addGuest()">
    <i class="now-ui-icons ui-1_simple-add text-white"></i>
  </n-button>
</template>
<script>
import moment from 'moment'
import { apiPost } from '@/utils/api'
import { getUserFromLocalStorage } from '@/utils/auth'
export default {
  props: ['data', 'event'],
  data() {
    return {
      isHost: false,
      user: getUserFromLocalStorage()
    }
  },
  watch: {
    event: async function (to) {
      this.isHost = (await apiPost('/attendee/host/' + to.id)).isHost
    }
  },
  methods: {
    addGuest: function () {
      this.$router.push('/invites/new?eventId=' + this.event.id)
    },
    removeGuest: async function (id) {
      let deleteAttendee = await this.$swal({
        title: 'Delete Attendee?',
        text: `Are you sure you would like to delete this attendee?`,
        type: 'question',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger btn-fill',
        cancelButtonClass: 'btn  btn-success btn-fill',
        confirmButtonText: 'Yes, Delete Attendee!',
        cancelButtonText: 'Keep Attendee',
        buttonsStyling: false
      })
      if (deleteAttendee.value) {
        await apiPost('/attendee/' + id + '/delete')
        this.$emit('refresh')
      }
    }
  },
  computed: {
    eventInFuture: function () {
      return this.selDate > this.nowDate
    },
    selDate: function () {
      return new Date(moment.utc(this.event.start).format('YYYY-MM-DD HH:mm:ss'))
    },
    nowDate: function () {
      return new Date(moment().format('YYYY-MM-DD HH:mm:ss'))
    }
  },
}
</script>
<style scoped>
img {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 15px;
}

.floatright {
  float: right;
}

.inviteGuest td,
th {
  vertical-align: middle;
}

.text-small {
  font-size: smaller;
}
</style>
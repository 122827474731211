<template>
    <div class="row">
        <div class="col-lg-12 ml-auto">
            <div class="mx-auto logo-center content-page">
                <a href="/"> <img src="https://instaevent.imgix.net/files/instaevent-logo-large.png?w=125"
                        class="content-logo" alt="InstaEvent"></a>
                <h1 class="home-box">InstaEvent</h1>
            </div>
            <div class="content-page mx-auto wtext">
                <div class="description">
                    <h1 class="info-title">Terms & Conditions</h1>
                    <p>Effective date: September 17, 2020</p>
                    <p><strong>Introduction</strong></p>

                    <p>These terms and conditions govern your use of this
                        website; by using this website, you accept these terms and conditions in
                        full.&nbsp;&nbsp; If you disagree with these terms
                        and conditions or any part of these terms and conditions, you must not use this
                        website. </p>

                    <p>This website uses cookies.&nbsp; By using this website and agreeing to these
                        terms and conditions, you consent to our InstaEvent&rsquo;s use of cookies in
                        accordance with the terms of InstaEvent&rsquo;s privacy policy / cookies policy.</p>

                    <p><strong>License to use website</strong></p>

                    <p>Unless otherwise stated, InstaEvent and/or its
                        licensors own the intellectual property rights in the website and material on
                        the website.&nbsp; Subject to the license
                        below, all these intellectual property rights are reserved.</p>

                    <p>You may view, download for caching purposes only, and
                        print pages from the website for your own personal use, subject to the
                        restrictions set out below and elsewhere in these terms and conditions.&nbsp; </p>


                    <p><strong>Acceptable use</strong></p>

                    <p>You must not use this website in any way that causes,
                        or may cause, damage to the website or impairment of the availability or
                        accessibility of the website; or in any way which is unlawful, illegal,
                        fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent
                        or harmful purpose or activity.</p>

                    <p>You must not use this website to copy, store, host,
                        transmit, send, use, publish or distribute any material which consists of (or
                        is linked to) any spyware, computer virus, Trojan horse, worm, keystroke
                        logger, rootkit or other malicious computer software.</p>

                    <p>You must not conduct any systematic or automated data
                        collection activities (including without limitation scraping, data mining, data
                        extraction and data harvesting) on or in relation to this website without InstaEvent
                        express written consent.</p>

                    <p>You must not use this website to transmit or send
                        unsolicited commercial communications.</p>

                    <p>You must not use this website for any purposes related
                        to marketing without InstaEvent express written consent.</p>

                    <p><strong>Restricted access</strong></p>

                    <p>InstaEvent reserves the right to restrict access to
                        [other] areas of this website, or indeed this entire website, at InstaEvent discretion.</p>

                    <p>If InstaEvent provides you with a user ID and password
                        to enable you to access restricted areas of this website or other content or
                        services, you must ensure that the user ID and password are kept
                        confidential.&nbsp; </p>

                    <p>InstaEvent may disable your user ID and password in InstaEvent
                        sole discretion without notice or explanation.</p>

                    <p><strong>User content</strong></p>

                    <p>In these terms and conditions, &ldquo;your user content&rdquo; means
                        material (including without limitation text, images, audio material, video
                        material and audio-visual material) that you submit to this website, for
                        whatever purpose.</p>

                    <p>Your user content must not be illegal or unlawful, must
                        not infringe any third party's legal rights, and must not be capable of giving
                        rise to legal action whether against you or InstaEvent or a third party (in
                        each case under any applicable law).&nbsp; </p>

                    <p>You must not submit any user content to the website
                        that is or has ever been the subject of any threatened or actual legal
                        proceedings or other similar complaint.</p>

                    <p>InstaEvent reserves the right to edit or remove any
                        material submitted to this website, or stored on InstaEvent servers, or hosted
                        or published upon this website.</p>

                    <p>Notwithstanding InstaEvent rights under these terms and
                        conditions in relation to user content, InstaEvent does not undertake to
                        monitor the submission of such content to, or the publication of such content
                        on, this website.</p>

                    <p><strong>No warranties</strong></p>

                    <p>This website is provided &ldquo;as is&rdquo; without any
                        representations or warranties, express or implied.&nbsp; InstaEvent makes no representations or
                        warranties in relation to this website or the information and materials
                        provided on this website.&nbsp; </p>

                    <p>Without prejudice to the generality of the foregoing paragraph,
                        InstaEvent does not warrant that:</p>

                    <p>l&nbsp; this website will be constantly available,
                        or available at all; or</p>
                    <p>l&nbsp; the information on this website is complete,
                        true, accurate or non-misleading.</p>

                    <p>Nothing on this website constitutes, or is meant to
                        constitute, advice of any kind.&nbsp; </p>

                    <p><strong>Limitations of liability</strong></p>

                    <p>InstaEvent will not be liable to you (whether under the
                        law of contact, the law of torts or otherwise) in relation to the contents of,
                        or use of, or otherwise in connection with, this website:</p>

                    <p>l&nbsp; to the extent that the website is provided
                        free-of-charge, for any direct loss;</p>
                    <p>l&nbsp; for any indirect, special or consequential
                        loss; or</p>
                    <p>l&nbsp; for any business losses, loss of revenue,
                        income, profits or anticipated savings, loss of contracts or business
                        relationships, loss of reputation or goodwill, or loss or corruption of
                        information or data.</p>

                    <p>These limitations of liability apply even if InstaEvent
                        has been expressly advised of the potential loss.</p>

                    <p><strong>Exceptions</strong></p>

                    <p>Nothing in these terms and conditions will exclude or
                        limit any warranty implied by law that it would be unlawful to exclude or
                        limit; and nothing in the terms and conditions will exclude or limit InstaEvent
                        liability in respect of any:</p>

                    <p>l&nbsp; death or personal injury caused by InstaEvent
                        negligence;</p>
                    <p>l&nbsp; fraud or fraudulent misrepresentation on the
                        part of InstaEvent; or</p>
                    <p>l&nbsp; matter which it would be illegal or unlawful
                        for InstaEvent to exclude or limit, or to attempt or purport to exclude or
                        limit, its liability. </p>

                    <p><strong>Reasonableness</strong></p>

                    <p>By using this website, you agree that the exclusions
                        and limitations of liability set out in these terms and conditions are
                        reasonable.&nbsp; </p>

                    <p>If you do not think they are reasonable, you must not
                        use this website.</p>

                    <p><strong>Other parties</strong></p>

                    <p>You accept that, as a limited liability entity, InstaEvent has an
                        interest in limiting the personal liability of its officers and employees.&nbsp; You agree that
                        you
                        will not bring any claim
                        personally against InstaEvent officers or employees in respect of any losses
                        you suffer in connection with the website.</p>

                    <p>Without prejudice to the foregoing paragraph, you agree
                        that the limitations of warranties and liability set out in this website
                        disclaimer will protect InstaEvent officers, employees, agents, subsidiaries,
                        successors, assigns and sub-contractors as well as InstaEvent. </p>

                    <p><strong>Unenforceable provisions</strong></p>

                    <p>If any provision of this website disclaimer is, or is
                        found to be, unenforceable under applicable law, that will not affect the
                        enforceability of the other provisions of this website disclaimer.</p>

                    <p><strong>Indemnity</strong></p>

                    <p>You hereby indemnify InstaEvent and undertake to keep InstaEvent
                        indemnified against any losses, damages, costs, liabilities and expenses
                        (including without limitation legal expenses and any amounts paid by InstaEvent
                        to a third party in settlement of a claim or dispute on the advice of InstaEvent
                        legal advisers) incurred or suffered by InstaEvent arising out of any breach by
                        you of any provision of these terms and conditions, or arising out of any claim
                        that you have breached any provision of these terms and conditions.</p>

                    <p><strong>Breaches of these terms and conditions</strong></p>

                    <p>Without prejudice to InstaEvent other rights under
                        these terms and conditions, if you breach these terms and conditions in any
                        way, InstaEvent may take such action as InstaEvent deems appropriate to deal
                        with the breach, including suspending your access to the website, prohibiting
                        you from accessing the website, blocking computers using your IP address from
                        accessing the website, contacting your internet service provider to request
                        that they block your access to the website and/or bringing court proceedings
                        against you.</p>

                    <p><strong>Variation</strong></p>

                    <p>InstaEvent may revise these terms and conditions from
                        time-to-time.&nbsp; Revised terms and conditions
                        will apply to the use of this website from the date of the publication of the
                        revised terms and conditions on this website.&nbsp;
                        Please check this page regularly to ensure you are familiar with the
                        current version.</p>

                    <p><strong>Assignment</strong></p>

                    <p>InstaEvent may transfer, sub-contract or otherwise deal
                        with InstaEvent rights and/or obligations under these terms and conditions
                        without notifying you or obtaining your consent.</p>

                    <p>You may not transfer, sub-contract or otherwise deal
                        with your rights and/or obligations under these terms and conditions.&nbsp; </p>

                    <p><strong>Severability</strong></p>

                    <p>If a provision of these terms and conditions is
                        determined by any court or other competent authority to be unlawful and/or
                        unenforceable, the other provisions will continue in effect.&nbsp; If any unlawful and/or
                        unenforceable
                        provision would be lawful or enforceable if part of it were deleted, that part
                        will be deemed to be deleted, and the rest of the provision will continue in
                        effect. </p>

                    <p><strong>Entire agreement</strong></p>

                    <p>These terms and conditions, together with our Privacy
                        Policy, Copyright Policy and Cookie Policy, constitute the entire agreement
                        between you and InstaEvent in relation to your use of this website and
                        supersede all previous agreements in respect of your use of this website.</p>

                    <p><strong>Law and jurisdiction</strong></p>

                    <p>These terms and conditions will be governed by and
                        construed in accordance with California Law, and any disputes relating to these
                        terms and conditions will be subject to the [non-]exclusive jurisdiction of the
                        courts of San Diego County.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.row {
    margin-top: -50px;
}

.content-logo {
    width: 125px;
}

.logo-center {
    text-align: center;
}
</style>

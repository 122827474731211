<template>
  <div>
    <div class="card-box">
        <vnud-card class="card-chart" subTitle="" description="" no-footer-line v-if="viewInvite">
          <template v-slot:header>
            <div class="non-login-event-logo" v-if="!IsValidLogin">
              <a class="d-flex align-items-center text-decoration-none justify-content-center" href="/">
                <img src="https://instaevent.imgix.net/files/instaevent-logo-large.png?w=125" alt="InstaEvent" title="Instantly create events" >
              </a>
            </div>
            <div class="title-event">
              <h2 class="card-title" :class="{'text-center': !IsValidLogin}">Event Invitation </h2>
            </div>
          </template>
          <div class="title-event">
            <p>{{ host.fullname ? host.fullname : host.email }} has invited you to the following event.</p>
            <h2>{{ event.name }}</h2>

            <strong>Description:</strong> {{ event.description }}
          </div>
          <br>
          <div>
            <div>
              <strong>For:</strong> {{ contact.name ? contact.name : contact.email }}
            </div>
            <div>
              <strong>Role:</strong> {{ invite.role }}
            </div>
            <div>
              <strong>Status:</strong> {{ invite.status }}
            </div>
            <div>
              <strong>Sent By:</strong> {{ host.fullname ? host.fullname : host.email }}
            </div>
            <div>
              <strong>Sent At:</strong> {{ formattedCreatedAt }}
            </div>
            <br>
            <div>
              <strong>Location:</strong> {{ location.text }}
            </div>
            <div>
              <strong>Start:</strong> {{ formatDate(event.start) }}
              <br>
              <span v-if="event.end"><strong>End:</strong> {{ formatDate(event.end) }}</span>
            </div>
            <div>
              <button @click="loadEvent()" type="button" class="btn btn-sm">
                <span class="btn-label">
                  <i class="now-ui-icons location_map-big"></i>
                </span>
                Map / Directions
              </button>
            </div>
          </div>
          <div v-if="viewEvent">
            <events-view-single :embedded="true" :event="event" :locationText="location.text" ref="single" />
          </div>
        </vnud-card>
        <vnud-card v-else>
          <br>
          <br>
          <br>
          <h2>Event Not Found</h2>
          <br>
          <br>
          <br>
        </vnud-card>
        <vnud-card class="card-chart" subTitle="Invite Status" description="" no-footer-line v-if="viewInvite">
          <div :class="{'d-none': viewEvent }" style="text-align: center;">
            <h5 class="mb-0">Please confirm your attendance</h5>
            <button @click="status('confirmed')" type="button" class="btn btn-sm"
              :class="{ 'btn-success': invite.status === 'confirmed' }">
              <span class="btn-label">
                <i class="now-ui-icons ui-1_check"></i>
              </span>
              Yes
            </button>

            <button @click="status('maybe')" type="button" class="btn btn-sm"
              :class="{ 'btn-warning': invite.status === 'maybe' }">
              <span class="btn-label">
                <i class="now-ui-icons tech_watch-time"></i>
              </span>
              Maybe
            </button>

            <button @click="status('declined')" type="button" class="btn btn-sm"
              :class="{ 'btn-danger': invite.status === 'declined' }">
              <span class="btn-label">
                <i class="now-ui-icons ui-1_simple-remove"></i>
              </span>
              No
            </button>
          </div>
          <div v-if="!IsValidLogin" class="d-flex justify-content-center ga-1 mb-4">
            <a href="/auth/register" class="btn btn-md m-0 text-uppercase"><i class="now-ui-icons tech_mobile"></i> Register </a>
            <a href="/auth/login" class="btn btn-md m-0 btn-success text-uppercase"><i class="now-ui-icons users_circle-08"></i> Login </a>
          </div>
          <div><img style="cursor:pointer" src="@/assets/img/google-play-and-apple-app-store-logos-22.png" />
          </div>
          <br>
        </vnud-card>
      </div>
  </div>
</template>

<script>
import moment from 'moment'
import { apiPost, apiPut, apiGet } from '@/utils/api'
import EventsViewSingle from '../Events/EventsViewSingle.vue'
import { VnudCard,} from '@/components/index.js'
import { userHasValidLogin } from '@/utils/auth.js'
export default {
  components: {
    VnudCard,
    EventsViewSingle
  },
  data() {
    return {
      viewInvite: true,
      user: { auth0: '', name: '' },
      contact: { name: '' },
      host: { name: '' },
      invite: {
        role: '',
        status: '',
        eventId: '',
        createdAt: '2000-01-01 00:00:00'
      },
      event: { id: 0, name: '' },
      viewEvent: false,
      location: { id: 0, text: '' }
    }
  },
  computed: {
    IsValidLogin(){
      return userHasValidLogin()
    },
    formattedCreatedAt() {
      return moment(this.invite.createdAt).format('MMM DD, YYYY hh:mm a')
    },
    userHasAuth0Account() {
      return this.user && !!this.user.auth0
    }
  },
  async created() {
    let data = {}
    try {
      data = await apiGet('/attendee/invite/' + this.$route.params.uuid)
    } catch (e) {
      this.viewInvite = false
      return
    }
    this.invite = data.attendee
    this.host = data.host
    this.user = data.user
    this.contact = data.contact
    this.location = data.location
    this.event = data.event
  },
  mounted() {
    if (this.user && this.user.id && this.invite.status !== 'invited') {
      this.$router.push('/auth/login')
    }
  },
  methods: {
    async status(inviteStatus) {
      this.invite = await apiPut('/attendee/invite/' + this.$route.params.uuid, { status: inviteStatus })
      if (!this.userHasAuth0Account) {
        this.$swal({
          title: 'Create InstaEvent Account?',
          text: 'Your response has been saved. Would you like to create an InstaEvent account?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Yes, create it!',
          buttonsStyling: false,
          customClass: {
            container: 'custom-swal',
            title: 'mb-0',
            icon: 'question-icon mt-0',
            cancelButton: 'my-0',
            confirmButton: 'my-0'
          }
        }).then(async (createAccount) => {
          if (!createAccount.value) return
          const { value: formValues } = await this.$swal.fire({
              title: "Set a new password",
              html: `
                <p>You will use this password to login to InstaEvent, together with your email: ${this.contact.email}</p>
                <input type="text" id="name" class="swal2-input form-control" placeholder="Enter your name" required>
                <input type="password" id="password" placeholder="Enter Password (> 8 chars)" class="swal2-input form-control" minlength="8" autocapitalize="off" autocorrect="off" required>
              `,
              focusConfirm: false,
              customClass: {
                container: 'custom-swal',
                title: 'mb-0',
                actions: 'mt-0',
                confirmButton: 'mt-0'
              },
              preConfirm: () => {
                return [
                  document.getElementById("name").value,
                  document.getElementById("password").value
                ];
              }
            });
            if (formValues) {
              let auth0User = await apiPost('/user/register', {
              name : formValues[0],
              email: this.contact.email,
              password: formValues[1],
              password_confirmation : formValues[1]
            });
            if (auth0User.success) {
              this.$swal({
                title: 'Success!',
                text: 'Your account has been created!',
                icon: 'success',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-success btn-fill',
                customClass: {
                  container: 'custom-swal',
                  title: 'mb-0',
                  icon: 'success-icon question-icon mt-0',
                  confirmButton: 'my-0'
                }
              }).then(() => {
                this.$router.push('/auth/login')
              })
            }
            }
        })
      }
    },
    formatDate(date) {
      return moment.utc(date).format('ddd, MMM D, YYYY @ h:mm a')
    },
    async loadEvent() {
      this.viewEvent = !this.viewEvent
    },
    closeEvent() {
      this.viewEvent = false
    }
  }
}
</script>

<style>
.btn-full {
  width: 250px;
}

.modal-content .modal-header button {
  top: 30px;
  outline: 0;
}

.modal-header .close {
  padding-top: 0px;
  margin: -20px -20px -20px auto;
}

.swal2-icon {
  margin: 0.25em auto 0.875em;
  height: 150px !important;
  width: 150px !important;
}

.swal2-icon-text {
  height: 100px;
  top: 34px;
  position: relative;
  font-size: 2.5em !important;
}

.title-event h1,
h2 {
  margin: 5px 0px 0px 0px;
}

.title-event h2 {
  font-size: 2.0em;
}
.custom-swal .swal2-popup {
    padding: 30px 20px;
}
.custom-swal .question-icon {
    height: 100px !important;
    width: 100px !important;
    margin-bottom: 20px;
}
.custom-swal .swal2-icon {
  height: 100px !important;
  width: 100px !important;
  margin-bottom: 20px;
}
.custom-swal .question-icon .swal2-icon-content {
  font-size: 50px;
}

.custom-swal .success-icon .swal2-success-ring {
  width: 50px !important;
  height: 50px !important;
  border: 3px solid rgba(24, 206, 15, 0.3) !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
}
.custom-swal .success-icon .swal2-success-circular-line-left,
.custom-swal .success-icon.swal2-icon.swal2-success [class^=swal2-success-circular-line] {
  height: auto !important;
}
.custom-swal .success-icon .swal2-success-fix {
  background-color: transparent !important;
}
.custom-swal .success-icon .swal2-success-line-tip {
    width: 17px !important;
    left: 33px !important;
    top: 53px !important;
    height: 4px !important;
}
.custom-swal .success-icon .swal2-success-line-long {
    width: 30px !important;
    right: 0 !important;
    top: 49px !important;
    transform: rotate(-45deg) !important;
    height: 4px !important;
    left: 42px !important;
}

.non-login-event-logo a {
    max-width: 75px;
    margin: auto;
}
</style>

<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="content content-page mx-auto">
    <div class="row">
      <div class="col-md-12 mx-auto logo-center">
        <a href="/"> <img src="https://instaevent.imgix.net/files/instaevent-logo-large.png?w=125" class="content-logo" alt="InstaEvent"></a>
        <h1 class="home-box">InstaEvent</h1>
      </div>
      <div class="col-lg-12 ml-auto">

        <div class="description">
          <h1 class="info-title">Investor Relations</h1>
          <p>InstaEvent is a privately held Corporation located in San Diego California (US).</p>
          <p>With current financial support through family and friends, we are seeking an early stage private angel
            investor who has a similar vision of changing the way we communicate with others through digital devices.
            This is an incredible opportunity for someone to become a part of a highly talented hardworking team with
            experience in several successful startups and acquisitions. </p>
          <p>If you would like to become a part of an exciting adventure, please contact us at <a
              href="mailto:info@instaevent.com" class="ilink text-decoration-underline">info@InstaEvent.com</a> for more information.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.row {
  margin-top: -50px;
}

.content-logo {
  width: 125px;
}

.logo-center {
  text-align: center;
}

.ilink {
  color: orange;
}
.content-page {
  max-width: 865px;
  width: 100%;
}
</style>

import { jwtDecode } from 'jwt-decode' // Use named import
import Cookies from 'js-cookie'

// Utility to extract query parameters from the URL
const getQueryParams = () => {
  const params = {}
  window.location.href.replace(/([^(?|#)=&]+)(=([^&]*))?/g, ($0, $1, $2, $3) => {
    params[$1] = $3
  })
  return params
}

// Extract token and secret from URL hash
export const extractInfoFromHash = () => {
  const { id_token, state } = getQueryParams()
  return {
    token: id_token,
    secret: state
  }
}

// Set API user in localStorage
export const setApiUser = (user) => {
  window.localStorage.setItem('apiUser', JSON.stringify(user))
}

// Set token in localStorage and cookies
export const setToken = (token) => {
  window.localStorage.setItem('token', token)
  window.localStorage.setItem('user', JSON.stringify(jwtDecode(token))) // Decode the JWT and store user info
  Cookies.set('jwt', token, { expires: 7 }) // Set token in cookies for 7 days
}

// Unset the token and remove user data
export const unsetToken = () => {
  window.localStorage.removeItem('token')
  window.localStorage.removeItem('user')
  window.localStorage.removeItem('secret')
  window.localStorage.removeItem('apiUser')  
  Cookies.remove('jwt')
  window.localStorage.setItem('logout', Date.now())
}

// Get token from cookies (client-side)
export const getTokenFromCookie = (req) => {
  const token = Cookies.get('jwt') // Check if token is available from cookies
  if (token) return token

  // If we're on server-side (e.g., SSR), extract from request headers
  if (!req || !req.headers.cookie) return
  const jwtCookie = req.headers.cookie.split(';').find((c) => c.trim().startsWith('jwt='))
  if (!jwtCookie) return
  return jwtCookie.split('=')[1]
}

// Get token from localStorage
export const getTokenFromLocalStorage = () => {
  const token = window.localStorage.token
  return token ? token : undefined
}


// Get user data from cookies
export const getUserFromCookie = (req) => {
  const token = getTokenFromCookie(req)
  return token ? jwtDecode(token) : undefined
}

// Get user data from localStorage
export const getUserFromLocalStorage = () => {
  const json = window.localStorage.getItem('user')
  return json ? JSON.parse(json) : undefined
}

// Get API user data from localStorage
export const getApiUserFromLocalStorage = () => {
  const json = window.localStorage.getItem('apiUser')
  return json ? JSON.parse(json) : undefined
}

// Set secret in localStorage
export const setSecret = (secret) => window.localStorage.setItem('secret', secret)

// Check if secret matches the stored one
export const checkSecret = (secret) => window.localStorage.getItem('secret') === secret

// Check if the user has a valid login based on JWT expiration
export const userHasValidLogin = () => {
  const user = getUserFromLocalStorage()
  // return !!(user && user.exp > Math.floor(Date.now() / 1000)) // Compare expiry with current time in seconds
  return !!(user && user.email) // Compare expiry with current time in seconds
}

// Check if the user has a Google user ?
export const isGoogleUser = () => {
  const user = getApiUserFromLocalStorage()
  return !!(user && user.auth0)
}

<template>
  <div class="bg-white login-warpper mx-auto w-100">
    <div class="login-header text-center position-relative pa-4 overflow-hidden">
      <img src="https://instaevent.imgix.net/files/instaevent-logo-large.png?w=125" alt="Logo" class="mx-auto mb-2" height="58">
      <h3 class="font-weight-regular mb-0">
          Account verification
      </h3>
    </div>
    <div class="login-tab">
      <form>
        <div class="pa-4">
          <div class="login-form">
            <Alert :error-bag="customError.errorBag" />
            <v-alert v-if="hasMessage" type="success">
              <div>{{ successMsgBag['status'] }}</div>
            </v-alert>
          </div>            
        </div>          
      </form>
    </div>
  </div>
</template>

<script setup>
import { apiPost } from '@/utils/api'
import Alert from "@/components/Alert.vue";
import { ref, inject, computed,onMounted } from "vue";
import { useRoute } from 'vue-router'
const token = ref('');
const route = useRoute();
const customError = inject('customError');

const  successMsgBag = customError.successMsgBag;
const hasMessage = computed(() => {
  return Object.keys(successMsgBag).length > 0;
});

const handleSubmit = async () => {
  if (token.value) {
    try {
        customError.resetErrorBag();
        const response = await apiPost('/auth/verify-email', {
         token: token.value
        });
     
        if(response.success){
          customError.transformSuccessMsg(response);
        }
      } catch (error) {
        customError.transformValidationErrors(error.response);
      }
  }
};

onMounted(async () => {
  if (route.query.token) {
    token.value = route.query.token;
    handleSubmit();
  }
})


</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
        <vnud-card class="stacked-form" title="Edit Group">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="card-title">Edit Group</h4>
              <div content="Go Back" :open-delay="300" placement="top">
                <n-button type="info" size="sm" @click="backToContactsGroups()">
                  <img class="action-button-icon" style="cursor:pointer"
                    src="@/assets/img/glyphicons-basic-309-square-empty-left.png" />
                </n-button>
              </div>
            </div>
          </template>
          <form method="#" action="#" @submit.prevent>
            <Alert :error-bag="customError.errorBag" class="mb-2" />
            <div>
              <fg-input v-model="group.name" label="Name" placeholder="Enter the group's name" />
              <!--
              <fg-input v-model="group.email" label="Email" placeholder="Enter the group's email" />
              -->
              <fg-input v-model="group.description" label="Description" placeholder="Enter the contact's description" />
              <n-button @click="saveGroup" class="text-white">Save Group</n-button>
            </div>
          </form>
        </vnud-card>
      </div>
    </div>
    <modal :show="showAlert" class="modal-primary" :show-close="false">
      <p>{{ alertMessage }}</p>
      <template v-slot:footer>
        <n-button type="neutral" link="" @click="showAlert = false">Try again</n-button>
      </template>
    </modal>
  </div>
</template>
<script>

import {
  Modal,
  VnudCard,
} from '@/components/index.js'
import { apiPut, apiGet } from '@/utils/api'
import Alert from "@/components/Alert.vue";

export default {
  components: {
    Modal,
    VnudCard,
    Alert
  },
  inject: ["customError"],
  data() {
    return {
      id: '',
      group: {
        name: '',
        description: '',
      },
      showAlert: false
    }
  },
  async mounted() {
    this.id = this.$route.params.groupId
    this.group = await apiGet('/contactGroup/' + this.id)
    this.customError.resetErrorBag();
  },
  methods: {
    saveGroup: async function () {
      if (!this.group.name) {
        this.alertMessage = 'Please enter all required group data'
        this.showAlert = true
      } else {
        try {
          await apiPut('/contactGroup/' + this.id, this.group)
          this.$router.push('/contacts-groups/')
        } catch (e) {
          this.customError.transformValidationErrors(e.response);
        }
      }
    },
    backToContactsGroups: function () {
      this.$router.push('/contacts-groups/')
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}
</script>
<style scoped>
.action-button-icon {
  filter: invert(1);
  width: 20px;
}
</style>

<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
        <vnud-card class="stacked-form" title="Create Contact">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="card-title">
                Create Contact
              </h4>
              <div content="Go Back" :open-delay="300" placement="top">
                <n-button type="info" size="sm" @click="backToContact">
                  <img class="action-button-icon" style="cursor:pointer"
                    src="@/assets/img/glyphicons-basic-309-square-empty-left.png" />
                </n-button>
              </div>
            </div>
          </template>
          <form method="#" action="#" @submit.prevent>
            <Alert :error-bag="customError.errorBag" class="mb-2" />
            <div>
              <fg-input v-model="name" label="Name" placeholder="Enter the contact's name">
              </fg-input>
              <fg-input v-model="email" label="Email" placeholder="Enter the contact's email">
              </fg-input>
              <fg-input v-model="phone" label="Phone" placeholder="Enter the contact's phone">
              </fg-input>
              <n-button @click="createContact" class="text-white">Submit</n-button>
            </div>
          </form>
        </vnud-card>
        <UploadCSV />
      </div>
    </div>
    <modal v-model:show="showAlert" class="modal-primary" :show-close="false">
      <template v-slot:header>
        <div class="modal-profile d-flex justify-content-center align-items-center">
          <i class="now-ui-icons location_pin"></i>
        </div>
      </template>
      <p>{{ alertMessage }}</p>
      <template v-slot:footer>
        <n-button type="neutral" link="" @click="showAlert = false">Try again</n-button>
      </template>
    </modal>
  </div>
</template>
<script>
import {
  Modal,
  VnudCard,
} from '@/components/index.js'
import UploadCSV from './UploadCSV.vue';
import { apiPost, apiGet } from '@/utils/api'
import Alert from "@/components/Alert.vue";
import { Checkbox } from "@/components/index";
export default {
  components: {
    Modal,
    VnudCard,
    UploadCSV,
    Alert,
    Checkbox
  },
  inject: ["customError"],
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      showAlert: false,
      alertMessage: '',
      contactGroup: '',
    }
  },
  mounted() {
    this.customError.resetErrorBag();
  },
  methods: {
    
    createContact: async function () {
      this.customError.resetErrorBag();
      if (!this.name || !this.email) {
        this.alertMessage = 'Please enter all required Contact data'
        this.showAlert = true
      } else if (!this.validEmail(this.email)) {
        this.alertMessage = 'Please enter a valid email address'
        this.showAlert = true
      } else {
        try {
          await apiPost('/contact', { name: this.name, email: this.email, phone: this.phone  })
          this.$router.push('/contacts/')
        } catch (error) {
          this.customError.transformValidationErrors(error.response);
        }
      }
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    backToContact() {
      this.$router.push('/contacts/')
    },
  }
}
</script>
<style scoped>
.action-button-icon {
  filter: invert(1);
  width: 20px;
}
</style>

import { reactive } from 'vue';

export default {
  install(app) {
    const errorBag = reactive({});
    const successMsgBag = reactive({});
    
    function formatContactsResponse(response) {
      let resultText = `${response.message || 'No message'}<br/>`;
    
      let failedText = 'Failed Contacts:<br/>';
      if (Array.isArray(response.failedContacts) && response.failedContacts.length > 0) {
        response.failedContacts.forEach((contact) => {
          if (contact.contactId) {
            failedText += `ID: ${contact.contactId},`;
          } 
          if (contact.name) {
            failedText += `Name: ${contact.name},`;
          } 
          if (contact.reason) {
            failedText += `Reason: ${contact.reason}`;
          } 
          failedText += `<br/>`;
        });

      } else {
        failedText += 'None<br/>';
      }
    
      let successText = 'Success Contacts:<br/>';
      if (Array.isArray(response.successContacts) && response.successContacts.length > 0) {
        response.successContacts.forEach((contact) => {
          if (contact.contactId) {
            successText += `ID: ${contact.contactId},`;
          } 
          if (contact.name) {
            successText += `Name: ${contact.name},`;
          } 
          if (contact.reason) {
            successText += `Reason: ${contact.reason}`;
          } 
          successText += `<br/>`;

        });
      } else {
        successText += 'None<br/>';
      }
    
      resultText += failedText + '<br/>' + successText;
      return resultText;  
    }
    

    
    function transformValidationErrors(response, handlerType = 'axios') {
      if (handlerType === 'axios') {
        if (response.data.errors) {
          for (let key in response.data.errors) {
            errorBag[key] = response.data.errors[key][0];
          }
        }
        if (response.data.error) {
          errorBag['error'] = response.data.error;
        }
      }
    }

    function resetErrorBag() {
      Object.keys(errorBag).forEach(key => {
        delete errorBag[key];
      });
    }

    function transformSuccessMsg(response, handlerType = 'axios') {
      if (handlerType === 'axios') {
        if (response.data.status) {
          successMsgBag['status'] = response.data.status;
        }
      }
    }

    function resetSuccessMsgBag() {
      Object.keys(successMsgBag).forEach(key => {
        delete successMsgBag[key];
      });
    }

    app.config.globalProperties.$customError = {
      errorBag,
      successMsgBag,
      transformValidationErrors,
      resetErrorBag,
      transformSuccessMsg,
      resetSuccessMsgBag
    };

    app.provide('customError', {
      errorBag,
      successMsgBag,
      transformValidationErrors,
      resetErrorBag,
      transformSuccessMsg,
      resetSuccessMsgBag,
      formatContactsResponse,
    });
  }
};

<template>
  <div>
    <div class="row">
      <div class="col-lg-8 col-xl-6 col-12">
        <vnud-card card-body-classes="table-full-width" description="These are the invites you've received"
          no-footer-line>
          <template v-slot:header>
            <h4 class="card-title">Invites Received</h4>
            <div>
              {{ numberOfInvites }} invites
            </div>
          </template>
          <el-table stripe style="width: 100%" :data="pagedInvites">
            <el-table-column label="Host">
              <template v-slot="{ row }">
                {{ row.host ? (row.host.fullname ? row.host.fullname : row.host.name) : '' }}
              </template>
            </el-table-column>
            <el-table-column label="Event">
              <template v-slot="{ row }">
                {{ row.event ? row.event.name : '' }}
              </template>
            </el-table-column>
            <el-table-column label="Date">
              <template v-slot="{ row }">
                {{ invitedAt(row.createdAt) }}
              </template>
            </el-table-column>
            <el-table-column header-align="right" label="Actions">
              <template v-slot="{ row }">
                <div class="text-right table-actions">
                  <n-button type="info" size="sm" @click="goTo(row.event.id)">
                    <i class="now-ui-icons ui-1_send text-white"></i>
                  </n-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="card-footer d-flex flex-sm-row flex-column align-items-center justify-content-between">
            <n-pagination class="pagination-no-border" :total="numberOfInvites" :perPage="perPage"
              v-model="invitePage" />
            <div class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="d-flex align-items-center">
                <span class="mr-2">Invites per page: </span>
                <el-select class="per-page-selector" placeholder="Invites per page" v-model="perPage">
                  <el-option v-for="option in perPageOptions" :value="option" :label="option" :key="option"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <h4 class="card-title pl-4 mt-0">Attending</h4>
          <el-table stripe style="width: 100%" :data="events.attending">
            <el-table-column min-width="170" prop="hostName" label="Host">
            </el-table-column>
            <el-table-column min-width="170" prop="name" label="Name">
            </el-table-column>
            <el-table-column min-width="110" prop="startsAt" label="Starts">
            </el-table-column>
            <el-table-column min-width="110" label="View" header-align="right">
              <template v-slot="{ row }">
                <div class="text-right table-actions">
                  <el-tooltip content="Info" :open-delay="300" placement="top">
                    <n-button type="info" size="sm" @click="goTo(row.id)">
                      <i class="now-ui-icons ui-1_calendar-60"></i>
                    </n-button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </vnud-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { apiGet } from '@/utils/api';
import {
  Pagination as NPagination,
  VnudCard,
} from '@/components/index.js';

export default {
  components: {
    VnudCard,
    NPagination,
  },
  data() {
    return {
      invites: [],
      invitePage: 1,
      perPage: 30,
      perPageOptions: [5, 10, 30, 50],
      events: [],
    };
  },
  async mounted() {
    this.invites = await apiGet('/attendee/received');
    this.events = await apiGet('/event')
  },
  computed: {
    pagedInvites() {
      let start = (this.invitePage - 1) * this.perPage;
      let end = start + this.perPage;
      return this.invites.slice(start, end);
    },
    numberOfInvites() {
      return this.invites.length;
    },
  },
  methods: {
    invitedAt(createdAt) {
      return moment(createdAt).format('MMM DD');
    },
    goTo(eventId) {
      this.$router.push('/events/' + eventId);
    },
  },
};
</script>

<style scoped>
.per-page-selector {
  width: 100px;
}

.datesent {
  width: 75px;
}
</style>

<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
        <vnud-card class="stacked-form" title="Create Event">
          <template v-slot:header>
            <h4 class="card-title">
              Create Event
            </h4>
          </template>
          <form method="#" action="#" @submit.prevent>
            <Alert :error-bag="customError.errorBag" class="mb-2" />
            <div>
              <fg-input v-model="name" label="Name" placeholder="Enter the event's title name" />
              <fg-input v-model="description" label="Description" placeholder="Describe the event" />
              <div class="form-group has-label">
                <label>Location</label>
                <v-autocomplete v-model="location" class="custom-autocomplete" variant="solo" density="compact"
                  :rounded="true" :items="options" placeholder="Where is the event?" v-model:search="searchQuery"
                  :loading="loading" no-data-text="No results found" hide-no-data @update:search="fetchData"
                  @blur="handleBlur" @focus="handleFocus"></v-autocomplete>
              </div>

              <!-- Start Date -->
              <label>Start Date</label>
              <v-dialog v-model="startDateMenu" width="290">
                <template #activator="{ props }">
                  <v-text-field v-model="startDateDisplay" v-bind="props" prepend-icon="mdi-calendar" readonly
                    label="Start Date"></v-text-field>
                </template>
                <v-date-picker v-model="startDate" :min="today" @update:modelValue="saveStartDate"></v-date-picker>
              </v-dialog>

              <!-- Start Time -->
              <label>Start Time</label>
              <v-dialog v-model="startTimeMenu" width="290">
                <template #activator="{ props }">
                  <v-text-field v-model="startTimeDisplay" v-bind="props" prepend-icon="mdi-clock-outline" readonly
                    label="Start Time"></v-text-field>
                </template>
                <v-time-picker v-model="startTime" :allowed-minutes="(m) => m % 15 === 0"
                  @update:modelValue="saveStartTime"></v-time-picker>
              </v-dialog>
              <label class="w-100">Select Media</label>
              <span class="w-100" style="margin-right: 5px;">
                <label for="file-upload" class="custom-file-upload">
                  <i class="fa fa-cloud-upload"></i> Select Default image
                </label>
                <input style="display:none" id="file-upload" slot="default" name="eventMedia" ref="file" type="file"
                  @change="handleFileUpload()" label="File" />
              </span>
              <div style="height:50px;" v-show="loadProgress > 0">
                <n-progress v-if="!loaded" :value="loadProgress ? loadProgress : 0" :height="15" type="success" />
              </div>

              <!-- Toggle optional end date/time -->
              <div>
                <button type="button" class="btn btn-sm text-white" @click="showEnd = !showEnd">End Time</button>
              </div>

              <div v-if="showEnd">

                <!-- End Date -->
                <label>End Date</label>
                <v-dialog v-model="endDateMenu" width="290">
                  <template #activator="{ props }">
                    <v-text-field v-model="endDateDisplay" v-bind="props" prepend-icon="mdi-calendar" readonly
                      label="End Date"></v-text-field>
                  </template>
                  <v-date-picker v-model="endDate" :min="startDate" @update:modelValue="saveEndDate"></v-date-picker>
                </v-dialog>

                <!-- End Time -->
                <label>End Time</label>
                <v-dialog v-model="endTimeMenu" width="290">
                  <template #activator="{ props }">
                    <v-text-field v-model="endTimeDisplay" v-bind="props" prepend-icon="mdi-clock-outline" readonly
                      label="End Time"></v-text-field>
                  </template>
                  <v-time-picker v-model="endTime" :allowed-minutes="(m) => m % 15 === 0"
                    @update:modelValue="saveEndTime"></v-time-picker>
                </v-dialog>
              </div>
              <br>
              <n-button class="btn-info text-white" style="float:right" @click="createEvent">Select Guests</n-button>
            </div>
          </form>
        </vnud-card>
      </div>
    </div>
    <modal v-model:show="showAlert" class="modal-primary" :show-close="false">
      <p>{{ alertMessage }}</p>
      <template v-slot:footer>
        <n-button type="neutral" link="" @click="showAlert = false">Try again</n-button>
      </template>
    </modal>
  </div>
</template>

<script>
import moment from 'moment'
import {
  Modal,
  VnudCard,
} from '@/components/index.js'
import { apiPost } from '@/utils/api'
import { VTimePicker } from 'vuetify/labs/VTimePicker'
import { apiPostFile } from '@/utils/api'
import { Progress } from '@/components/index.js'
import Alert from "@/components/Alert.vue";
export default {
  components: {
    Modal,
    VnudCard,
    VTimePicker,
    [Progress.name]: Progress,
    Alert
  },
  inject: ["customError"],
  data() {
    let today = new Date();
    return {
      name: '',
      description: '',
      location: '',
      today,
      startDate: today, // Keep dates as Date objects
      startTime: '12:00', // Use simple time format
      endDate: today,
      endTime: '14:00',
      showEnd: false,
      showAlert: false,
      alertMessage: 'Please enter all required Event data',
      startDateMenu: false,
      startTimeMenu: false,
      endDateMenu: false,
      endTimeMenu: false,
      options: [], // Holds the API-fetched options
      loading: false, // Loading state during API call
      searchQuery: '', // Holds user input for search
      file: '',
      mediaUri: '',
      mediaType: '',
      loadProgress: 0,
      loaded: false,
      intervalId: null,
    }
  },
  computed: {
    startDateDisplay() {
      return this.startDate ? moment(this.startDate).format('MMM Do YY') : '';
    },
    startTimeDisplay() {
      return this.startTime ? moment(this.startTime, 'HH:mm').format('h:mm a') : '';
    },
    endDateDisplay() {
      return this.endDate ? moment(this.endDate).format('MMM Do YY') : '';
    },
    endTimeDisplay() {
      return this.endTime ? moment(this.endTime, 'HH:mm').format('h:mm a') : '';
    },
    hasFile: function () {
      return !!this.file
    }
  },
  mounted() {
    this.customError.resetErrorBag();
  },
  methods: {
    saveStartDate(value) {
      this.startDate = value;
      this.startDateMenu = false; // Close the dialog after selecting a date
    },
    saveStartTime(value) {
      this.startTime = moment(value, 'HH:mm').format('HH:mm');
      this.startTimeMenu = false; // Close the dialog after selecting a time
    },
    saveEndDate(value) {
      this.endDate = value;
      this.endDateMenu = false; // Close the dialog after selecting an end date
    },
    saveEndTime(value) {
      this.endTime = moment(value, 'HH:mm').format('HH:mm');
      this.endTimeMenu = false; // Close the dialog after selecting an end time
    },
    createEvent: async function () {
      this.customError.resetErrorBag();
      if (!this.name || !this.description || !this.location || !this.startDate || !this.startTime) {
        this.alertMessage = 'Please enter all required Event data';
        this.showAlert = true;
        return;
      }

      const startDateTime = moment(this.startDate).format('YYYY-MM-DD') + ' ' + this.startTime;
      const endDateTime = this.showEnd ? moment(this.endDate).format('YYYY-MM-DD') + ' ' + this.endTime : null;

      if (moment(startDateTime).isAfter(endDateTime)) {
        this.alertMessage = 'Event start must be before its end';
        this.showAlert = true;
        return;
      }

      // API call to create the event
      try {
        const event = await apiPost('/event', {
          name: this.name,
          description: this.description,
          location: this.location,
          start: startDateTime,
          end: endDateTime,
        });

        if (event && this.hasFile) {
          this.uploadFile(event.id)
        } else {
          this.$router.push('/invites/new?eventId=' + event.id);
        }
      } catch (error) {
        this.customError.transformValidationErrors(error.response);
      }
    },

    async fetchData(input) {
      if (!input) {
        this.options = [];
        return;
      }
      this.loading = true;
      try {
        let response = await apiPost('/location/search', { 'text': input })
        this.options = response
      } catch (error) {
        console.error('Error fetching data:', error);
        this.options = [];
      } finally {
        this.loading = false;
      }
    },
    handleBlur() {
      // If the input is not found in the options, use the typed input as the selected value
      if (!this.options.includes(this.location)) {
        this.location = this.searchQuery;
      }

      // Keep the placeholder visible by not clearing the searchQuery on blur unless a value is selected
      if (!this.location) {
        this.searchQuery = ''; // Resets to show placeholder if no option was selected
      }

      if (this.location === '' && this.searchQuery === '') {
        this.searchQuery = null;
        this.location = null;
      }
    },
    handleFocus() {
      if (!this.location) {
        this.searchQuery = ''; // Prepare input for new search on focus
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0]
    },
    async uploadFile(eventId) {
      if (!this.hasFile) {
        return this.$swal({
          title: `Please select default event image`,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-danger btn-fill'
        })
      }
      this.intervalId = setInterval(() => { if (this.loadProgress < 100) this.loadProgress += 1 }, 100)
      let formData = new FormData()
      formData.append('eventMedia', this.file)
      formData.append('eventId', eventId)
      await apiPostFile('/media', formData)

      clearInterval(this.intervalId)
      this.intervalId = null
      this.loadProgress = 100

      setTimeout(() => {
        this.loaded = true
        this.$router.push('/invites/new?eventId=' + eventId);
      }, 1000)
    }
  }
}
</script>

<style scoped>
.suggestion-item {
  cursor: pointer;
}

.time-control {
  width: 290px;
  margin-left: auto;
  margin-right: auto;
}
.custom-file-upload {
  font-size: 14px;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
</style>

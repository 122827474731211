<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <side-bar @click="toggleSidebar" v-if="IsValidLogin">
      <template v-slot:links="">
        <sidebar-item :link="{ name: 'Dashboard', icon: 'now-ui-icons travel_info', path: '/dashboard' }">
        </sidebar-item>
        <sidebar-item :link="{ name: 'Events', icon: 'now-ui-icons ui-1_calendar-60', path: '/events' }">
        </sidebar-item>
        <sidebar-item :link="{ name: 'Invites', icon: 'now-ui-icons ui-1_send', path: '/invites/received' }">
        </sidebar-item>       
        <sidebar-item :link="{ name: 'Contacts', icon: 'now-ui-icons education_agenda-bookmark', path: '/contacts' }">
        </sidebar-item>
        <sidebar-item :link="{ name: 'Contacts Groups', icon: 'now-ui-icons education_agenda-bookmark', path: '/contacts-groups' }">
        </sidebar-item>
        <sidebar-item :link="{ name: 'Locations', icon: 'now-ui-icons location_pin', path: '/locations' }">
        </sidebar-item>
      </template>
      <div class="versionTxt">
        <a class="text-decoration-none ml-4" href="mailto:info@instaevent.com?subject=Regarding v1.001 - User Feedback"> Feedback</a>
        Beta. v1.001
      </div>
    </side-bar>

    <div class="main-panel" :class="{'w-100': !IsValidLogin }">
      <top-navbar></top-navbar>
      <router-view name="header"></router-view>

      <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        <router-view></router-view>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>

import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import { userHasValidLogin } from '@/utils/auth.js'
export default {
  components: {
    TopNavbar,
    ContentFooter,
  },
  computed: {
    IsValidLogin(){
      return userHasValidLogin()
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  mounted() {
  }
};
</script>

<style lang="scss">
/* Style code remains the same */
$scaleSize: .95;

@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}

.versionTxt {
  color: #666666;
  margin: 0px 0px 20px 4px;
  font-size: smaller;
  padding-left: 4px;
  position: absolute;
  bottom: 0px;
}

.versionTxt a {
  color: #295585;
}
</style>

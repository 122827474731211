<template>
  <div class="logout-page">
    <div class="centered">
      <p>Sign-out Success ...</p>
    </div>
  </div>
</template>

<script>
import { unsetToken } from '@/utils/auth'

export default {
  name: 'logout-page',
  mounted () {
    unsetToken()
    setTimeout(() => {
      this.$router.push('/')
    }, 3000);
  }
}
</script>
<style lang="scss" scoped>
.logout-page {
  .full-page > .content,
  .centered {
    min-height: calc(100vh - 160px);
  }

  .not-found-icon {
    margin-right: 10px;
  }
  .centered {
    display: flex;
    padding-bottom: 150px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    p {
      font-size: 20px;
    }
  }
}
</style>